





































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { setHours, sub } from 'date-fns';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';
import TitleOptionQuestion from '@/components/QuestionsUser/TitleOptionQuestion/TitleOptionQuestion.vue';
import OptionQuestion from '@/components/QuestionsUser/OptionQuestion/OptionQuestion.vue';
import InputCalendar from '@/components/QuestionsUser/InputCalendar/InputCalendar.vue';
import ScheduleTable from '@/components/QuestionsUser/ScheduleTable/ScheduleTable.vue';
import Footer from '@/components/Footer/Footer.vue';
import Accordion from '@/components/Accordion/index.vue';
import AccordionLoading from '@/components/AccordionLoading/index.vue';
import Loading from '@/components/Loading/Loading.vue';

import PlannerService from '@/services/Planner/PlannerService';
import HistoricService from '@/services/Historic/historicService';

import ActiveModal from '@/share/Util/ActiveModal';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import WrapperQuestion from './components/WrapperQuestion/WrapperQuestion.vue';
import FeedbackQuestion from './components/FeedbackQuestion/FeedbackQuestion.vue';
import AccordionTopics from './components/AccordionTopics/AccordionTopics.vue';
import ModalHistoric from './components/ModalHistoric/ModalHistoric.vue';
import InstructionUser from './components/InstructionUser/InstructionUser.vue';
import plannerQuestions from './QuestionsPlanner.json';
import Sidebar from './PlannerConfigSidebar.vue';

const OBJECT_STORE = [
  'setPlannerDate',
  'setPlannerTimeUser',
  'setPlannerExerciseMethod',
  'setPlannerFocus',
  'setPlannerSeries',
  'setPlannerForeignLanguage',
  'setPlannerRedaction',
  'setPlannerHistoric',
];
const GET_VALIDATE = 2;
const BORDER_COLOR_ACCORDION = 'var(--neutralcolor-low-medium)';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    WrapperQuestion,
    TitleOptionQuestion,
    OptionQuestion,
    InputCalendar,
    ScheduleTable,
    Checkbox,
    FeedbackQuestion,
    AccordionTopics,
    Accordion,
    ModalHistoric,
    InstructionUser,
    Footer,
    AccordionLoading,
    Loading,
  },
})
export default class PlannerConfig extends Vue {
  private readInstruction = false;
  private currentQuestion = 0;
  private isLoadingButton = false;

  private defaultQuestions = plannerQuestions;
  private arrayQuestions: Array<Record<string, any>> = [];

  private configUser: Record<string, any> = {};
  private plannerQuestion: Record<string, any> = {};

  private topicsContent: Array<Record<string, any>> = [];

  private disciplinesExclude: Array<number> = [];
  private topicsExclude: Array<number> = [];

  private BORDER_COLOR_ACCORDION = BORDER_COLOR_ACCORDION;

  private setModal = new ActiveModal();
  private PlannerService = new PlannerService();
  private HistoricService = new HistoricService();

  created() {
    this.resetStatePlanner();
    this.loaderArrayQuestions();
  }

  mounted() {
    this.setTopicsContent();
    this.onMobileChange();
    this.setSideBook();
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'PlannerConfig',
          local: 'Criar meu planner',
        },
      },
    });
  }

  startConfiguration() {
    this.readInstruction = true;
  }

  // A função abaixo controla o modal de histórico
  modalDeleteHistoric(id: number, index: number) {
    this.setChoiceOption(id, index);
  }

  loaderArrayQuestions() {
    this.arrayQuestions = JSON.parse(JSON.stringify(this.defaultQuestions));
  }

  resetStatePlanner() {
    this.$store.commit('resetState');
  }

  // A função abaixo atualiza os dados dos tópicos conforme exista alguma alteração no retorno da API
  @Watch('$store.getters.plannerTopics', {
    deep: true,
  })
  setTopicsContent() {
    const storeRopicsContent = this.$store.getters.plannerTopics;
    if (storeRopicsContent.length && this.topicsContent !== storeRopicsContent) {
      this.topicsContent = storeRopicsContent;
    }
  }

  // A função abaixo verifica se o botão de criar o planner pode ser habilitado
  get isValidatePlanner() {
    const storeValidate = this.$store.getters.plannerInteraction.validatePlanner;
    return storeValidate;
  }

  // A Função abaixo Salva o planner
  async savePlanner() {
    this.isLoadingButton = true;

    try {
      const plannerConfig = this.$store.getters.plannerValidateData;
      const plannerhistoric = this.$store.getters.plannerHistoric;

      const response = await this.PlannerService.savePlanner(plannerConfig);

      if (response.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.setTrackAmplitude();

        if (plannerhistoric?.id === 1) {
          await this.HistoricService.deleteVideoHistoric();
        }

        this.$router.push({ path: '/planner' });
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar o Planner.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingButton = false;
    }
  }

  // As funções abaixo realiza a requisição a API para pegar os dados conforme o usuario chega em determinada questão
  @Watch('currentQuestion')
  async saveConfigUser() {
    if (this.currentQuestion === GET_VALIDATE) {
      const userConfig = this.$store.getters.plannerConfigUser;
      await this.PlannerService.postUserConfig(userConfig);
    }
  }

  @Watch('currentQuestion')
  async saveTopicsContent() {
    if (this.currentQuestion === 7) {
      const topicsContent = this.$store.getters.plannerTopicsContent;
      this.$store.commit('setPlannerTopics', []);
      await this.PlannerService.postTopics(topicsContent);
    }
  }

  // A Computed abaixo Controla quando o botão de validar o planer será mostrado
  get ableButtonValidate() {
    return this.$store.getters.plannerInteraction?.ableButtonValidate;
  }

  // A Computed abaixo Controla quando o retorno do topicContent sem a disciplina estrangeira não escolhida pelo aluno
  get topicsContentNoLanguage() {
    if (this.$store.getters.plannerQuestion.foreignLanguage?.value === 'espanhol') {
      return this.topicsContent.filter((topic) => topic?.id !== 3);
    }
    if (this.$store.getters.plannerQuestion.foreignLanguage?.value === 'ingles') {
      return this.topicsContent.filter((topic) => topic?.id !== 13);
    }
    return this.topicsContent;
  }

  // As funções abaixo controla a navegação do usuário pelas as questões e limpa a url com sujeira se precisar
  clearHashURL() {
    setTimeout(() => {
      window.history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
    }, 5);
  }

  setNavigationLeft(index: number) {
    let newIndex = index;
    if (newIndex > 0) {
      newIndex -= 1;
    }

    this.currentQuestion = newIndex;
    this.clearHashURL();
  }

  setNavigationRight(index: number) {
    let newIndex = index;
    if (newIndex < this.arrayQuestions.length - 1 && this.arrayQuestions[index].response) {
      newIndex += 1;
    }

    this.currentQuestion = newIndex;
    this.clearHashURL();
  }

  nextQuestion(nameID: string) {
    this.setNavigationRight(this.currentQuestion);

    const a = document.createElement('a');
    a.setAttribute('href', `#${nameID}-${this.currentQuestion}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.clearHashURL();
  }

  // As funções abaixo são acionadadas pelos eventos das questões do usuario (Verificar os eventos que são emitido no template)
  setStore(index: number, data: any) {
    if (index < OBJECT_STORE.length) {
      this.$store.commit(OBJECT_STORE[index], data);
    }
  }

  setOption(mutiple: boolean, id: number, index: number) {
    if (mutiple) {
      this.setMutipleChoiceOption(id, index);
      return;
    }

    if (index === 7 && id === 1) {
      this.setModal.activeModal('modalPlannerHistoric');
      return;
    }

    this.setChoiceOption(id, index);
  }

  verifyKeyObject(object: Record<string, any>, key: string) {
    return Object.keys(object).some((itemKey: string) => itemKey === key);
  }

  setResponseQuestion(index: number) {
    const newArray = this.arrayQuestions;

    if (this.verifyKeyObject(newArray[index], 'options')) {
      newArray[index].response = newArray[index].options.some((item: Record<string, any>) => item.select === true);

      this.arrayQuestions = newArray;
      return;
    }

    if (this.verifyKeyObject(newArray[index], 'table')) {
      let result = false;
      if (newArray[index].table.select.length > 0) {
        for (let i = 0; i < newArray[index].table.select.length - 1; i += 1) {
          if (result) {
            break;
          }
          const timelist = newArray[index].table.select[i];
          for (let j = 0; j < timelist.length; j += 1) {
            if (timelist[j].select) {
              result = true;
              break;
            }
          }
        }
        newArray[index].response = result;
        this.arrayQuestions = newArray;
        return;
      }
    }

    if (this.verifyKeyObject(newArray[index], 'date')) {
      if (newArray[index].date.select.start && newArray[index].date.select.end) {
        newArray[index].response = true;
      } else {
        newArray[index].response = false;
      }
      this.arrayQuestions = newArray;
      return;
    }

    this.arrayQuestions = newArray;
  }

  setChoiceOption(id: number, index: number): void {
    const newArray = this.arrayQuestions;
    if (newArray[index].options) {
      newArray[index].options.forEach((option: Record<string, any>, indexItem: number) => {
        if (option.id === id) {
          newArray[index].options[indexItem].select = true;
          this.setStore(index, newArray[index].options[indexItem]);
        } else {
          newArray[index].options[indexItem].select = false;
        }
      });
    }
    this.setResponseQuestion(index);
    this.arrayQuestions = newArray;
    this.nextQuestion('questionConfigPlanner');
  }

  setMutipleChoiceOption(id: number, index: number): void {
    const newArray = this.arrayQuestions;
    if (newArray[index].options) {
      newArray[index].options.forEach((option: Record<string, any>, indexOption: number) => {
        if (option.id === id) {
          newArray[index].options[indexOption].select = !newArray[index].options[indexOption].select;
        }
      });
      this.setStore(index, newArray[index].options);
    }

    this.setResponseQuestion(index);
    this.arrayQuestions = newArray;
  }

  setTableOptions(matrizSelect: Array<Array<Record<string, any>>>, index: number) {
    const newArray = this.arrayQuestions;
    if (newArray[index].table) {
      newArray[index].table.select = matrizSelect;
      this.setStore(index, matrizSelect);
    }

    this.setResponseQuestion(index);
    this.arrayQuestions = newArray;
  }

  setDateOption(date: Record<string, any>, mainIndex: number) {
    const newDate = {
      start: date?.start && sub(new Date(date?.start), { hours: 3 }),
      end: date?.end && setHours(new Date(date?.end), 20),
    };
    const newArray = this.arrayQuestions;
    if (newArray[mainIndex].date) {
      newArray[mainIndex].date.select = newDate;
      this.setStore(mainIndex, newDate);
    }

    this.setResponseQuestion(mainIndex);

    this.arrayQuestions = newArray;
  }

  handleRemakeTime() {
    this.$store.commit('setPlannerAbleButtonValidate', true);
    this.currentQuestion = 0;
  }

  handleRemakeContent() {
    this.$store.commit('setPlannerAbleButtonValidate', true);
    this.currentQuestion = 8;
  }

  async validatePlanner() {
    this.$store.commit('setPlannerLoadingValidate', null);
    this.$store.commit('setPlannerAbleButtonValidate', false);

    if (this.currentQuestion === 1) {
      const userConfig = this.$store.getters.plannerConfigUser;
      this.currentQuestion = 9;
      await this.PlannerService.postUserConfig(userConfig);
      return;
    }

    const storeValidateData = this.$store.getters.plannerValidateData;
    this.currentQuestion = 9;
    await this.PlannerService.postValidate(storeValidateData);
  }

  // As função abaixo realiza a atualização do checkbox da disciplina e seus tópicos
  setCheckbox(name: string, checked: boolean, idDisciplina: number) {
    const setChecked = !checked;
    const newTopicsContent: Array<Record<string, any>> = this.topicsContent;

    const indexTopic = newTopicsContent.findIndex((discipline: Record<string, any>) => discipline.id === idDisciplina);

    if (indexTopic !== -1) {
      newTopicsContent[indexTopic].checked = setChecked;
      this.verifyDisciplinesExclude(idDisciplina, setChecked);
      newTopicsContent[indexTopic].topics = this.addCheckedTrueTopics(newTopicsContent[indexTopic]?.topics, setChecked);
      let ids: Array<number> = [];

      newTopicsContent[indexTopic].topics.forEach((topic: Record<string, any>) => {
        ids = [...ids, ...this.getIdsTopics(topic, [])];
      });

      if (setChecked) {
        this.removeIdsTopicExclude(ids);
      } else {
        this.addIdsTopicExclude(ids);
      }
    }

    this.topicsContent = newTopicsContent;
  }

  addCheckedTrueTopics(topics: Array<Record<string, any>>, checked: boolean) {
    const newTopicsContent: Array<Record<string, any>> = topics;

    newTopicsContent.forEach((topic: Record<string, any>, indexTopic: number) => {
      newTopicsContent[indexTopic].checked = checked;
      if (topic?.topics && topic?.topics.length) {
        this.addCheckedTrueTopics(topic?.topics, checked);
      }
    });

    return newTopicsContent;
  }

  verifyDisciplinesExclude(id: number, checked: boolean) {
    let newDisciplinesExclude: Array<number> = this.disciplinesExclude;

    if (checked) {
      if (newDisciplinesExclude.includes(id)) {
        newDisciplinesExclude = newDisciplinesExclude.filter((item: number) => item !== id);
      }
    } else if (!newDisciplinesExclude.includes(id)) {
      newDisciplinesExclude.push(id);
    }

    this.disciplinesExclude = newDisciplinesExclude;
  }

  // As funções abaixo são para atualização do checkbox do topic
  setCheckedTopic(name: string, checked: boolean, indexTopic: number, idDiscipline: number) {
    const newTopicsContent = this.topicsContent;
    const indexDiscipline = newTopicsContent.findIndex((discipline) => discipline.id === idDiscipline);
    if (indexDiscipline > -1) {
      newTopicsContent[indexDiscipline].topics = this.searchTopic(newTopicsContent[indexDiscipline].topics, idDiscipline, indexTopic, !checked);
    }

    this.topicsContent = newTopicsContent;
  }

  searchTopic(listTopics: Array<Record<string, any>>, idDiscipline: number, indexTopic: number, checked: boolean) {
    const newListTopics: Array<Record<string, any>> = listTopics;
    if (newListTopics && newListTopics.length) {
      newListTopics.forEach((topic: Record<string, any>, indexTopicItem: number) => {
        if (topic?.topicId && topic?.topicId === indexTopic) {
          newListTopics[indexTopicItem].checked = checked;

          newListTopics[indexTopicItem].topics = this.addCheckedTrueTopics(topic.topics, checked);

          const arrayIdsTopics = this.getIdsTopics(topic, []);

          if (checked) {
            this.removeIdsTopicExclude(arrayIdsTopics);
          } else {
            this.addIdsTopicExclude(arrayIdsTopics);
          }

          if (topic.topID !== 0) {
            this.verifyTopicsParentChecked(idDiscipline, topic.topID, checked);
          } else {
            this.verifyDisciplineChecked(idDiscipline, checked);
          }

        } else {
          this.searchTopic(topic.topics, idDiscipline, indexTopic, checked);
        }
      });
    }

    return newListTopics;
  }

  getIdsTopics(listTopics: Record<string, any>, ids: Array<number>) {
    const newListTopics = listTopics;
    let newIds = ids;

    if (newListTopics && Object.entries(newListTopics).length) {
      newIds = [...newIds, newListTopics?.topicId];
      newListTopics.topics.forEach((topic: Record<string, any>) => {
        newIds = [...newIds, topic?.topicId];
        if (topic && Object.entries(topic).length) {
          this.getIdsTopics(topic, newIds);
        }
      });
    }

    return newIds;
  }

  removeIdsTopicExclude(arrayIdsTopics: Array<number>) {
    const newTopicExclude = this.topicsExclude;

    arrayIdsTopics.forEach((idTopic: number) => {

      const indexId = newTopicExclude.findIndex((idExclude: number) => idExclude === idTopic);

      if (indexId !== -1) {
        newTopicExclude.splice(indexId, 1);
      }
    });

    this.topicsExclude = newTopicExclude;
  }

  addIdsTopicExclude(arrayIdsTopics: Array<number>) {
    const newTopicExclude = this.topicsExclude;

    arrayIdsTopics.forEach((idTopic: number) => {
      if (!newTopicExclude.includes(idTopic)) {
        newTopicExclude.push(idTopic);
      }
    });

    this.topicsExclude = newTopicExclude;
  }

  verifyDisciplineChecked(idDiscipline: number, checked: boolean) {
    const newTopicsContent = this.topicsContent;
    const indexDiscipline = newTopicsContent.findIndex((discipline: Record<string, any>) => discipline.id === idDiscipline);

    if (indexDiscipline > -1) {
      if (checked) {
        if (!newTopicsContent[indexDiscipline].checked) {
          newTopicsContent[indexDiscipline].checked = checked;
          this.verifyDisciplinesExclude(newTopicsContent[indexDiscipline].id, checked);
        }
      } else {
        const allTopicsChecked = newTopicsContent[indexDiscipline].topics.some((topic: Record<string, any>) => topic.checked);
        if (!allTopicsChecked) {
          newTopicsContent[indexDiscipline].checked = checked;
          this.verifyDisciplinesExclude(newTopicsContent[indexDiscipline].id, checked);
        }
      }
    }

    this.topicsContent = newTopicsContent;
  }

  verifyTopicsParentChecked(idDiscipline: number, idTopicParent: number, checked: boolean) {
    if (idTopicParent === 0) {
      this.verifyDisciplineChecked(idDiscipline, checked);
      return;
    }

    const newTopicsContent = this.topicsContent;
    const indexDiscipline = newTopicsContent.findIndex((discipline) => discipline.id === idDiscipline);
    if (indexDiscipline > -1) {
      const { topID, topics } = this.searchTopicsParent(newTopicsContent[indexDiscipline].topics, idDiscipline, idTopicParent, checked);
      newTopicsContent[indexDiscipline].topics = topics;
      this.topicsContent = newTopicsContent;
      this.verifyTopicsParentChecked(idDiscipline, topID, checked);
    }

    this.topicsContent = newTopicsContent;
  }

  searchTopicsParent(listTopics: Array<Record<string, any>>, idDiscipline: number, indexTopic: number, checked: boolean) {
    const newListTopics: Array<Record<string, any>> = listTopics;
    let topID = indexTopic;

    if (newListTopics && newListTopics.length) {
      newListTopics.forEach((topic: Record<string, any>, indexTopicItem: number) => {
        if (topic?.topicId && topic?.topicId === indexTopic) {
          topID = topic?.topID;

          if (checked) {
            if (!topic.checked.checked) {
              newListTopics[indexTopicItem].checked = checked;
              this.removeIdsTopicExclude([topic?.topicId]);
            }
          } else {
            const allTopicsChecked = topic?.topics.some((topicItems: Record<string, any>) => topicItems.checked);
            if (!allTopicsChecked) {
              newListTopics[indexTopicItem].checked = checked;
              this.addIdsTopicExclude([topic?.topicId]);
            }
          }

        } else {
          this.searchTopicsParent(topic.topics, idDiscipline, indexTopic, checked);
        }
      });
    }
    return {
      topID,
      topics: newListTopics,
    };
  }

  // As funções abaixo atualiza as discplina e os topicos excluidos na store
  @Watch('disciplinesExclude')
  updateDisciplineExcludeStore() {
    this.$store.commit('setPlannerDisciplinesExclude', this.disciplinesExclude);
  }

  @Watch('topicsExclude')
  updateTopicsExclude() {
    this.$store.commit('setPlannerTopicsExclude', this.topicsExclude);
  }

  // As duas funções abaixo são para atualizar os valores da Sidebar, quando o usuário interagindo
  @Watch('$store.getters.plannerQuestionSidebar', {
    deep: true,
  })
  setConfigUser() {
    const storePlannerQuestion = this.$store.getters.plannerQuestionSidebar;
    this.plannerQuestion = storePlannerQuestion;
  }

  @Watch('$store.getters.plannerResponse', {
    deep: true,
  })
  setPlannerQuestion() {
    const storeConfigUser = this.$store.getters.plannerResponse.configUser;
    if (Object.entries(storeConfigUser).length) {
      this.configUser = {
        totalDaysPlanner: storeConfigUser?.days,
        totalWeekPlanner: storeConfigUser?.weeks.length,
      };
    }
  }

  @Watch('configUser')
  @Watch('plannerQuestion')
  setSideBook() {
    const ComponentClass = Vue.extend(Sidebar);
    const instance = new ComponentClass({ propsData: { ...this.configUser, ...this.plannerQuestion } });

    this.$store.commit('setSidebarExistence', true);

    if (this.isMobile) {
      this.$store.commit('setSidebar', false);
    } else {
      this.$store.commit('setSidebar', true);
    }

    this.$sidebar.setSidebar(instance);
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
  }

  @Watch('isMobile')
  onMobileChange() {
    this.$store.commit('setSidebar', !this.isMobile);
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      { title: 'Configuração', to: null },
    ]);
  }

}
